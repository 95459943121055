import { graphql, navigate } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import PageSection from 'ui-kit/page-section/page-section';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { FamilyAccountForm } from 'components/family-account-form';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';

import { refreshUnauthenticatedSession } from 'state/account/account.reducers';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    familyAccountActions,
    familyAccountGetSecretKeyHashRoutine,
    familyAccountVerifyUserRoutine
} from 'state/family-account/family-account.reducer';
import {
    familyAccountSecurityTokenSelector,
    familyAccountUserBearerTokenSelector
} from 'state/family-account/family-account.selectors';

import { VerifyUserResponse } from 'types/auto-refill';

import { getPhoneNumber } from 'util/globalVariables';
import { formatBirthDate } from 'util/string';

import './family-account.style.scss';

const FamilyAccount = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.FamilyAccountGetLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { familyAccountMan, backgroundImage } = data;
    const securityToken = useSelector(familyAccountSecurityTokenSelector);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const params = new URLSearchParams(location.search);
    const securityTokenParameter = params.get('securityToken');

    useEffect(() => {
        if (securityTokenParameter) {
            dispatch(familyAccountActions.setSecurityToken({ securityToken: securityTokenParameter }));
        } else {
            navigate('/link-expired?flow=caregiver-access');
        }
    }, [dispatch, securityTokenParameter]);

    // Once security token is set in redux, get the secret key hash value to be used when authenticating (after verification)
    useEffect(() => {
        if (securityToken) {
            // To validate the security token we run a verifyUser request
            // which will inform us if the token is valid
            dispatch(
                familyAccountVerifyUserRoutine.trigger({
                    dateOfBirth: '',
                    zipcode: '',
                    onSuccess: () => {
                        // If token is not expired, get hash
                        dispatch(
                            familyAccountGetSecretKeyHashRoutine.trigger({
                                // If invalid hash, return expired
                                onFailure: () => {
                                    navigate('/link-expired?flow=caregiver-access');
                                }
                            })
                        );
                    },
                    onFailure: () => {
                        // If token expired redirect to expired link
                        navigate('/link-expired?flow=caregiver-access');
                    },
                    onMaxNumAttemptsReached: () => {
                        navigate('/verification-failed?flow=caregiver-access');
                    }
                })
            );
        }
    }, [dispatch, securityToken]);

    const handleShowErrorModal = useCallback(
        (error: VerifyUserResponse) => {
            dispatch(
                openModal({
                    showClose: false,
                    className: 'custom-caregivers-modal modal-dialog-centered',
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.familyAccountFailure.title')} icon="alert" />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={error.messageText}
                            note={t('modals.familyAccountFailure.footNotes', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.familyAccountFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'FamilyAccountNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmit = useCallback(
        (formData) => {
            const { dobDay, dobMonth, dobYear, zipcode } = formData;
            setIsSubmitting(true);
            dispatch(
                familyAccountVerifyUserRoutine.trigger({
                    dateOfBirth: formatBirthDate(dobYear, dobMonth, dobDay),
                    zipcode: zipcode,
                    onSuccess: () => {
                        dispatch(refreshUnauthenticatedSession());
                    },
                    onFailure: (error: VerifyUserResponse) => {
                        setIsSubmitting(false);
                        handleShowErrorModal(error);
                    },
                    onMaxNumAttemptsReached: () => {
                        setIsSubmitting(false);
                        navigate('/verification-failed?flow=caregiver-access');
                    }
                })
            );
        },
        [dispatch, handleShowErrorModal]
    );

    const familyAccountBearerToken = useSelector(familyAccountUserBearerTokenSelector);
    useEffect(() => {
        if (familyAccountBearerToken) {
            navigate('/caregiver-access/manage');
        }
    }, [familyAccountBearerToken]);

    return (
        <MarketingPageLayout backgroundImage={backgroundImage} metaData={{}}>
            <PageSection>
                <Container fluid className={'family-account'} id={'family-account'}>
                    <Row>
                        <Col sm="12" lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col lg="8" className="family-account-form-container">
                                    <p className="h5 family-account-eyebrow text-left">
                                        {t('pages.familyAccount.userVerification.eyebrow')}
                                    </p>
                                    <h1 className="family-account-title text-left">
                                        {t('pages.familyAccount.userVerification.title')}
                                    </h1>

                                    <div className="spacer text-left mx-0 d-block">&nbsp;</div>

                                    <p className="h4 family-account-form-instructions text-left">
                                        {t('pages.familyAccount.userVerification.instructions')}
                                    </p>

                                    <FamilyAccountForm
                                        onSubmit={(e) => handleSubmit(e)}
                                        formName="FamilyAccountForm"
                                        submittingForm={isSubmitting}
                                    />
                                </Col>
                                <Col
                                    className="family-account-image-container"
                                    xs={{ span: 8, offset: 0 }}
                                    sm={{ span: 6, offset: 3 }}
                                    lg={{ span: 4, offset: 0 }}
                                >
                                    {familyAccountMan && (
                                        <GatsbyImage
                                            fluid={familyAccountMan?.childImageSharp?.fluid}
                                            image={getImage(familyAccountMan)}
                                            alt={'family-account-form-image'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default FamilyAccount;

export const query = graphql`
    query FamilyAccountGetLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        familyAccountMan: file(relativePath: { eq: "assets/images/smiling-woman-with-cat.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
